import React, { useState, useEffect } from 'react'

import classes from "./cookie-corner.module.scss"

const  getCookie = (cname) => {
    if (typeof window === "undefined" || !window.document) return;
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

const CookieCorner = () => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (getCookie("response") === "true") {
            setShow(false);
        } else {
            setShow(true);
        }
    }, []);


    const toggleShow = () => {
        setShow(!show);
    }

    const setAnalytics = () => {
        if (typeof window === "undefined" || !window.document) return;
        if (getCookie("ga-disable-UA-164761879-2") === "true") document.cookie="ga-disable-UA-164761879-2=false"
        document.cookie="gdprAnalyticsEnabled=true; expires=Thu, 31 Dec 2030 23:59:59 UTC; path=/"
        document.cookie="response=true; expires=Thu, 31 Dec 2030 23:59:59 UTC; path=/"
        toggleShow()
        window.trackGoogleAnalytics()
    } 

    const deleteAnalytics = () => {
        if (typeof window === "undefined" || !window.document) return;
        document.cookie="gdprAnalyticsEnabled=false; expires=Thu, 31 Dec 2030 23:59:59 UTC; path=/"
        document.cookie="response=true; expires=Thu, 31 Dec 2030 23:59:59 UTC; path=/"
        toggleShow()
        window.gaOptout()
    }

    return (
    <div className={ classes.corner }>

        <div className={[classes.options, (show ? "" : classes.hidden)].join(' ')}>
            <div className={classes.header}><h3>Cookies</h3><button className={classes.close} onClick={() => toggleShow()}>X</button></div>
            <div className={classes.body}>
                <p>This site uses cookies to store information on your computer.</p>
                <hr />
                <p>Necessary cookies support the site's core functionality and can only be disabled by changing your browser preferences.</p>
                <hr />
                <p>Analytical cookies help us improve the site by collecting and reporting information on its usage. You can accept or deny these cookies here.</p>
            </div>
            <div>
                <button className={ classes.accept } onClick={() => setAnalytics()}>Accept</button>
                <button className={ classes.deny } onClick={() => deleteAnalytics()}>Deny</button>
            </div>
        </div>
        <button className={ classes.icon }  onClick={() => toggleShow()}>
            C
        </button>

    </div>
    )

}

export default CookieCorner