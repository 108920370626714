import React from 'react'

import Link from 'gatsby-link'

import classes from "./footer.module.scss"

const Footer = () => (

    <footer className={classes.footer}>
        <p>© {new Date().getFullYear()} Enzo Galano & Associates</p>
        <Link to="/privacy-policy">Privacy Policy</Link>
    </footer>

)

export default Footer