import React from "react"

import { AnchorLink as Link } from "gatsby-plugin-anchor-links";

import Logo from "./images/logo"

import classes from "./header.module.scss"

const Header = () => (
  <header className={classes.header}>

    <div className={classes.brand}>
        <Link to="/#hero" stripHash><Logo /></Link>
    </div>
    <ul className={classes.list}>
        <li>
            <Link to="/#contact" stripHash>Contact</Link>
        </li>
    </ul>
  </header>
)

export default Header